<template>
  <div class="text-left">
    <v-app-bar
      v-scroll="onScroll"
      :color="!isScrolling ? 'transparent' : 'white black--text'"
      fixed
      flat
    >
      <v-container grid-list-lg>
        <!-- <v-row class="hidden-sm-and-down">
          <v-col cols="6" align="left" class="mt-3">
            <v-toolbar-title>
                <v-img
                  v-if="isLogoWhite"
                  :src="require('../assets/img/logo_onebox-horizontal-white.png')"
                  width="100"
                />
                <v-img
                  v-if="isLogoColor"
                  :src="require('../assets/img/logo_onebox-horizontal-png.png')"
                  width="100"
                />
            </v-toolbar-title>
          </v-col>
          <v-col cols="6" align="right" class="mt-3  white--text">
            <div class="hidden-sm-and-down">
              <v-btn to="/" text class="mr-2" v-if="isBtnWhite">
               หน้าหลัก</v-btn
              >
              <v-menu
                offset-y
                bottom
                open-on-hover
                transition="slide-y-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text>
                    ข้อมูลให้บริการ One Box
                    <v-icon color="white">mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list class="text-center" rounded>
                  <v-list-item link to="/dataprotection">
                    <v-list-item-title>
                      นโยบายการคุ้มครอง
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link to="/datause">
                    <v-list-item-title>
                      ข้อกำหนดการใช้บริการ
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
                <v-btn
                dark
                text
                 href="https://box.one.th/login"
                        target="_blank"
                >เข้าสู่ระบบ</v-btn
              >
            </div>
          </v-col>
        </v-row> -->
          <v-layout row wrap fill-height align-center justify-center>
            <v-flex lg2 class="text-left hidden-md-and-down">
              <v-img
                v-if="isLogoWhite"
                :src="require('@/assets/img/logo_onebox-horizontal-white.png')"
                width="100"
              />
              <v-img
                v-if="isLogoColor"
                :src="require('@/assets/img/logo_onebox-horizontal-png.png')"
                width="100"
              />
            </v-flex>
            <v-flex
              lg10
              class="toolbarDesktop text-right white--text"
            >
            <div class="mr-n12 ml-n12 hidden-sm-and-down">
              <v-row  justify="center" v-if="isBtnWhite">
                <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn to="/" text dark v-on="on" class="mr-2 font-weight-bold" 
                      >{{$t('landingpagenavbar.home')}}</v-btn
                    >
                  </template>
                </v-menu>
                <v-menu
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class=" white--text mr-2 font-weight-bold" >
                      {{$t('landingpagenavbar.oneboxserviceinfo')}}
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="text-center" rounded>
                    <v-list-item link to="/dataprotection">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.dataprotectionpolicy')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/datause">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.termsofservice')}}
                      </v-list-item-title>
                    </v-list-item>
                     <v-list-item link to="/dataetax">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.termsofuseofelectronic')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu  
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition">
                   <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class=" white--text mr-2 font-weight-bold">
                      {{$t('landingpagenavbar.manual')}}
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="text-center" rounded>
                    <v-list-item v-for="(item, i) in listmanual" :key="i" @click="clickManual(item.link)">
                      <v-list-item-title v-if="$t('default') === 'th'" style="line-height: 24px">
                        {{ item.document_name_th }}
                      </v-list-item-title>
                      <v-list-item-title v-else style="line-height: 24px">
                        {{ item.document_name_eng }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- <v-list class="text-center" rounded>
                    <v-list-item @click="clickManual(1)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.businessbox')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(2)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.oneboxmanual')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(3)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.etax')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(4)">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.useonebox')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(5)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.storage')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list> -->
                </v-menu>
                <v-menu  
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition">
                   <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class=" white--text mr-2 font-weight-bold" :disabled="maintenanceStatus">
                      {{$t('landingpagenavbar.registeraccount')}}
                      <v-icon color="white">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                   <v-list class="text-center" rounded  v-if="mohpormt == true">
                       <v-list-item link to="/register_mohpromt2">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerone')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/register">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerbusiness')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-list class="text-center" rounded v-else>
                       <v-list-item link to="/registerone">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerone')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/register">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerbusiness')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="5"
                    rounded
                    class="font-weight-bold"
                    dark
                    color="#005799"
                    v-on="on"
                    @click="$router.push('/listpackage')"
                    target="_blank"
                    >
                    <v-icon size="20px" class="mr-2">mdi-cart</v-icon> -->
                    <!-- ซื้อแพ็กเกจ -->
                    <!-- {{$t('packagenew.purchase_package')}}
                    </v-btn
                  >
                </template>
                </v-menu> -->
               <!-- <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                 
                      class="font-weight-bold"
                    text
                    dark
                    v-on="on"
                    @click="$router.push('/registerone')"
                    target="_blank"
                    >สมัครสมาชิก</v-btn
                  >
                </template>
                </v-menu> -->
                <!-- ภาษา -->
                <v-menu 
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class=" white--text mr-2 font-weight-bold">
                      <flag :iso="defaultflag" :squared="false" />
                    </v-btn>
                  </template>
                  <v-list nav dense>
                    <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
                      <v-list-item-action>
                        <flag :iso="item.flag" :squared="false" />
                      </v-list-item-action>
                      <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- login ผ่าน OneId ปิดเผื่อใช้ login อันใหม่ -->
                <!-- <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      dark
                      v-on="on"
                      :disabled="maintenanceStatus"
                      class="font-weight-bold"
                      @click="$router.push('/loginbysso')"
                      target="_blank"
                      >{{ $t(log_in) }}</v-btn
                    >
                  </template>
                </v-menu> -->
              </v-row>
              <v-row justify="center" v-if="isBtnBlack">
                <v-menu bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn to="/" text v-on="on" class="mr-2 font-weight-bold">{{$t('landingpagenavbar.home')}}</v-btn>
                  </template>
                </v-menu>

                <v-menu
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" text class=" black--text mr-2 font-weight-bold">
                      {{$t('landingpagenavbar.oneboxserviceinfo')}}
                      <v-icon color="black">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="text-center" rounded>
                    <v-list-item link to="/dataprotection">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.dataprotectionpolicy')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/datause">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.termsofservice')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/dataetax">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.termsofuseofelectronic')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn 
                      v-on="on"
                      text
                      class="mr-4 font-weight-bold" 
                    >
                      {{$t('landingpagenavbar.manual')}}
                      <v-icon color="black">mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="text-center" rounded>
                    <v-list-item v-for="(item, i) in listmanual" :key="i" @click="clickManual(item.link)">
                      <v-list-item-title v-if="$t('default') === 'th'" style="line-height: 24px">
                        {{ item.document_name_th }}
                      </v-list-item-title>
                      <v-list-item-title v-else style="line-height: 24px">
                        {{ item.document_name_eng }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <!-- <v-list class="text-center" rounded>
                    <v-list-item @click="clickManual(1)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.businessbox')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(2)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.oneboxmanual')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(3)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.etax')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(4)">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.useonebox')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="clickManual(5)">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.storage')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list> -->
                </v-menu>
                <v-menu  
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      v-on="on"
                      class=" black--text mr-2 font-weight-bold"
                      :disabled="maintenanceStatus">
                        {{$t('landingpagenavbar.registeraccount')}}
                        <v-icon color="black">mdi-menu-down</v-icon>
                      </v-btn>
                  </template>
                   <v-list class="text-center" rounded>
                    <v-list-item link to="/registerone">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerone')}}
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item link to="/register">
                      <v-list-item-title style="line-height: 24px">
                        {{$t('landingpagenavbar.registerbusiness')}}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- <v-menu>
                <template v-slot:activator="{ on }">
                  <v-btn
                    elevation="5"
                    rounded
                    class="font-weight-bold"
                    dark
                    color="#005799"
                    v-on="on"
                    @click="$router.push('/listpackage')"
                    target="_blank"
                    >
                    <v-icon size="20px" class="mr-2">mdi-cart</v-icon> -->
                    <!-- ซื้อแพ็กเกจ -->
                    <!-- {{$t('packagenew.purchase_package')}}
                    </v-btn
                  >
                </template>
                </v-menu> -->
                <!-- ภาษา -->
                <v-menu 
                  offset-y
                  bottom
                  open-on-hover
                  transition="slide-y-transition"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text class=" white--text mr-2 font-weight-bold">
                      <flag :iso="defaultflag" :squared="false" />
                    </v-btn>
                  </template>
                  <v-list nav dense>
                    <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
                      <v-list-item-action>
                        <flag :iso="item.flag" :squared="false" />
                      </v-list-item-action>
                      <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- login ผ่าน OneId ปิดเผื่อใช้ login อันใหม่ -->
                <!-- <v-menu bottom >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      text
                      v-on="on"
                      class="font-weight-bold"
                      @click="$router.push('/loginbysso')"
                      target="_blank"
                      :disabled="maintenanceStatus"
                      >{{ $t(log_in) }}</v-btn
                    >
                  </template>
                </v-menu> -->
              </v-row>
              </div>
            </v-flex>
          </v-layout>

        <div class="hidden-md-and-up">
          <v-row>
            <v-col cols="6">
              <v-toolbar-title class="pt-1">
                <v-img
                  v-if="isLogoWhite"
                  :src="
                    require('@/assets/img/logo_onebox-horizontal-white.png')
                  "
                  width="90"
                />
                <v-img
                  v-if="isLogoColor"
                  :src="require('@/assets/img/logo_onebox-horizontal-png.png')"
                  width="90"
                />
              </v-toolbar-title>
            </v-col>
            <v-col cols="6" align="right" class="pt-4">
              <v-btn
                text
                color="white"
                v-if="isLogoWhite"
                @click.stop.prevent="drawerLP = !drawerLP"
                style="font-weight: bold"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
              <v-btn
                text
                color="black"
                v-if="isLogoColor"
                @click.stop.prevent="drawerLP = !drawerLP"
                style="font-weight: bold"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer
      right
      app
      temporary
      clipped
      :disable-resize-watcher="true"
      v-model="drawerLP"
      width="310px"
    >
      <div class="text-left">
        <v-list nav dense>
          <v-layout justify-space-between style="color:#333333">
          <v-subheader style="font-size:13px;color:#333333"><b>{{$t("balloon.menu")}}</b></v-subheader>
          <v-btn class="mt-2 mr-2" text icon small @click.stop.prevent="drawerLP = !drawerLP">
          <v-icon size="20" style="color:#333333">mdi-close</v-icon>
          </v-btn>
          </v-layout>
          <v-list-item-group
            active-class="indigo--text text--blue-grey darken-3"
          >
            <v-list-item link to="/">
              <v-list-item-icon>
                <v-icon>mdi-home-variant-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{$t('landingpagenavbar.home')}}</v-list-item-title>
            </v-list-item>
            <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px">

            <v-list-group prepend-icon="mdi-file-document">
              <template v-slot:activator>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.oneboxserviceinfo')}}</v-list-item-title>
              </template>
              <v-list-item link to="/dataprotection">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.dataprotectionpolicy')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-shield-lock-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item link to="/datause">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title>{{$t('landingpagenavbar.termsofservice')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-ballot-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item link to="/dataetax">
                      <v-list-item-title>
                        {{$t('landingpagenavbar.termsofuseofelectronic')}}
                      </v-list-item-title>
                      <v-list-item-icon>
                  <v-icon>mdi-ballot-outline</v-icon>
                </v-list-item-icon>
                    </v-list-item>
            </v-list-group>
            <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px">
            <v-list-group prepend-icon="mdi-book-open-page-variant">
              <template v-slot:activator>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.manual')}}</v-list-item-title>
              </template>
              <v-list-item v-for="(item, i) in listmanual" :key="i" @click="clickManual(item.link)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title v-if="$t('default') === 'th'" style="line-height: 24px">
                  {{ item.document_name_th }}
                </v-list-item-title>
                <v-list-item-title v-else style="line-height: 24px">
                  {{ item.document_name_eng }}
                </v-list-item-title>
                <v-list-item-icon v-if="item.document_name_th.search('VDO') !== -1">
                  <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-icon v-else>
                  <v-icon>mdi-book-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <!-- <v-list-item @click="clickManual(1)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">
                  {{$t('landingpagenavbar.businessbox')}}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-book-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="clickManual(2)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">
                  {{$t('landingpagenavbar.oneboxmanual')}}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-book-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="clickManual(3)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">
                  {{$t('landingpagenavbar.etax')}}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-book-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="clickManual(4)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title>
                  {{$t('landingpagenavbar.useonebox')}}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="clickManual(5)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">
                  {{$t('landingpagenavbar.storage')}}
                </v-list-item-title>
                <v-list-item-icon>
                  <v-icon>mdi-arrow-right-drop-circle-outline</v-icon>
                </v-list-item-icon>
              </v-list-item> -->
            </v-list-group>
            <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px">
            <!-- <v-list-item link to="/">
              <v-list-item-icon>
                <v-icon>mdi-account-multiple-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>สมัครสมาชิก</v-list-item-title>
            </v-list-item> -->
            <v-list-group prepend-icon="mdi-account-plus">
              <template v-slot:activator>
                <v-list-item-title>{{$t('landingpagenavbar.registeraccount')}}</v-list-item-title>
              </template>
            <v-list v-if="mohpormt == true">
              <v-list-item link to="/register_mohpromt2">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.registerone')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>people</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item ink to="/register">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.registerbusiness')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>business</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item link to="/registerone">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.registerone')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>people</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item ink to="/register">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t('landingpagenavbar.registerbusiness')}}</v-list-item-title>
                <v-list-item-icon>
                  <v-icon>business</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
            </v-list-group>
            <!-- <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px">
            <v-list-item link to="/listpackage">
              <v-list-item-icon>
                <v-icon>mdi-cart</v-icon>
              </v-list-item-icon>
              <v-list-item-title style="line-height: 2rem;">{{$t('packagenew.purchase_package')}}</v-list-item-title>
            </v-list-item>
            <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px"> -->
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-icon>
                  <flag :iso="defaultflag" :squared="false"/>
                </v-list-item-icon>
                <v-list-item-title>{{ $t('default') === 'en' ? $t('languages.english'): $t('languages.thai')}}</v-list-item-title>
              </template>
              <v-list-item v-for="(item, i) in language" :key="i" @click="changelang(item.data)">
                <v-list-item-icon></v-list-item-icon>
                <v-list-item-title style="line-height: 24px">{{$t(item.name)}}</v-list-item-title>
                <v-list-item-icon>
                  <flag :iso="item.flag" :squared="false"/>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
            <hr style="height:0.1px;border-width:0;color:gray;background-color:#71B5D8;margin:0px 7px">
            <!-- login ผ่าน OneId ปิดเผื่อใช้ login อันใหม่ -->
            <!-- <v-list-item @click="$router.push('/loginbysso')">
              <v-list-item-icon>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t(log_in) }}</v-list-item-title>
            </v-list-item> -->
          </v-list-item-group>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import VueCookies from "vue-cookies";
import Swal from "sweetalert2/dist/sweetalert2.js";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  data: function() {
    return {
      mohpormt:false,
      maintenanceStatus: false,
      isLogoWhite: true,
      isLogoColor: false,
      isBtnWhite: true,
      isBtnBlack: false,
      isScrolling: false,
      drawerLP: null,
      menu: null,
      log_in:'landingpagenavbar.login',
      defaultflag: "",
      language: [
        {
          flag: "us",
          name: "languages.english",
          data: "en",
        },
        {
          flag: "th",
          name: "languages.thai",
            data: "th",
        },
      ],
      listmanual: []
    };
  },
  methods: {
    clickManual(url) {
      window.open(url)
      // if (item === 1) {
      //   window.open("https://box.one.th/public?id=9dd5dc8ef27ec253b80b86ceeec01565")
      // }else if (item === 2) {
      //   window.open("https://box.one.th/public?id=e9230decd699c822bdf809f12dd3bf3f")
      // }else if (item === 3) {
      //   window.open("https://box.one.th/public?id=94bd222c3c4448b25fe48fa72e04c3a4")
      // }else if (item === 4) {
      //   window.open("https://box.one.th/public?id=16bc24e8c021f817415aaf1ec74de382")
      // }else if (item === 5) {
      //   window.open("https://box.one.th/public?id=f53838c9e82dda307a37c1f919d6f4d9")
      // }
    },
    fnGetManual() {
      this.axios
        .get(
          process.env.VUE_APP_SERVICE_ADMIN +
            "/api/admin/get_user_manual?type=basic"
        )
        .then((res) => {
          if (res.data.status == "OK") {
            this.listmanual = res.data.result;
          } else {
            Toast.fire({
              icon: "error",
              title: this.$t("manual.manualfail"),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fire({
            icon: "error",
            title: this.$t("manual.manualfail"),
          });
        });
    },
    onScroll() {
      const offset = window.pageYOffset;
      this.isScrolling = offset > 80;
      this.isLogoWhite = offset < 80;
      this.isLogoColor = offset > 80;
      this.isBtnWhite = offset < 80;
      this.isBtnBlack = offset > 80;
    },
    changelang(parameter) {
      localStorage.setItem("lang", parameter);
      this.$i18n.locale = parameter;
      let dflag = this.language.findIndex((v) => v.data === parameter);
      this.defaultflag = this.language[dflag]["flag"];
    },
  },
  mounted (){
    this.fnGetManual();
    if (VueCookies.get('checklogin') === null){
      this.log_in = 'landingpagenavbar.login'
    } else {
      if (VueCookies.get('checklogin') === "true"){
        this.log_in = 'landingpagenavbar.gotoonebox'
      } else {
        this.log_in = 'landingpagenavbar.login'
      }
    }
    if(this.$route.path === '/moph/loginwithid' || VueCookies.get("check_mohpromt") == "true"){
      this.mohpormt = true;
    }
    this.$vuetify.theme.dark = false;
  },
  created() {
    this.changelang(localStorage.getItem("lang"));
  },
};
</script>
<style>

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
  margin-right: 17px;
}
.active{
  color:white;
  background-color: #184966;
}
.non-active{
  color:#184966;
  background-color: white;
  

}
@import "../../assets/css/navbar.css";
</style>
